import styled from "styled-components";
import { fonts, landingPalette } from "../styles";

export const Hero = styled.header`
  position: relative;
  padding: 0 130px 0 80px;
  z-index: 0;
    justify-content: space-between;

  @media (max-width: 600px) {
    padding: 30px 32px 30px 32px;
  }

  @media (max-width: 425px) {
    padding: 30px 16px 0 16px;
  }

  ::after {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    opacity: 0.54;
  }

    .hero-content {
        display: flex;
        min-height: 530px;
        flex-direction: row; 
        max-width: 1280px;
        width: 100%;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 768px) {
            flex-direction: column; 
            justify-content: start;
            align-items: start; 
            text-align: start; 
        }
    }
    
`;

export const CompaniesSection = styled.section`
  padding: 4rem 80px 10rem 80px;

  @media (max-width: 600px) {
    padding: 4rem 32px 10rem 32px;
  }

  @media (max-width: 425px) {
    padding: 4rem 16px 10rem 16px;
  }

  h2 {
    font-size: 38px;
    line-height: 48px;
    color: ${landingPalette.blue};
    font-family: ${fonts.SofiaProSemiBold};
    margin-bottom: 4rem;
    text-align: center;
  }

  .companies-section {
    max-width: 1280px;
    width: 100%;
    margin: auto;
  }
`;

export const TalentSpecialistSection = styled.section`
  padding: 0 80px 10rem 80px;

  @media (max-width: 600px) {
    padding: 0 16px 10rem 16px;
  }

  @media (max-width: 425px) {
    padding: 0 16px 10rem 16px;
  }

  .main-landing-container-row {
    gap: 15rem;
    @media (max-width: 920px) {
      flex-direction: column-reverse;
        gap: 8rem;
    }
  }

  figure {
    min-width: 360px;
    max-width: 560px;
    width: 100%;

    @media (max-width: 920px) {
      max-width: 560px;
    }
  }

  article {
    max-width: 500px;
  }

  button,
  a {
    border: none;
    outline: none;
    border-radius: 21px;
    min-height: 44px;
    font-size: 1.6rem;
    color: white;
    line-height: 2rem;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
  }
`;

export const InfoServicesSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  padding: 7rem 80px 9rem 80px;

  @media (max-width: 600px) {
    padding: 7rem 32px 9rem 32px;
  }

  @media (max-width: 425px) {
    padding: 7rem 16px 9rem 16px;
  }

  ::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: " ";
    opacity: 0.54;
    z-index: -1;
  }

  .main-title-services {
    display: flex;
    position: relative;
    margin-bottom: 7rem;

    img {
      width: 10rem;
      object-fit: scale-down;
      position: absolute;
      left: -4rem;
      top: -3rem;
    }

    h2 {
      font-size: 38px;
      line-height: 48px;
      color: white;
      font-family: ${fonts.SofiaProSemiBold};

      @media (max-width: 920px) {
        text-align: center;
      }
    }
  }

  .sevices-list {
    display: flex;
    justify-content: space-between;
    gap: 28px;
    max-width: 1280px;
    width: 100%;
    margin: auto;

    @media (max-width: 920px) {
      flex-direction: column;
    }
  }

  .service-item {
    max-width: 400px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    padding: 2rem 3.8rem;

    @media (max-width: 920px) {
      max-width: 200%;
    }

    figure {
      width: 45px;
      height: 45px;
      background-color: #F7F5F1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      margin-bottom: 20px;
        
    }

    img {
      width: 30px;
      object-fit: scale-down;
     
    }

    h3 {
      font-size: 32px;
      line-height: 39px;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProBold};
      margin-bottom: 8px;
    }

    p {
      font-size: 18px;
      color: #707070;
      font-family: ${fonts.SofiaProMedium};
    }
  }
`;

export const MainInfoSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 7rem;
  padding: 12.2rem 80px 7.5rem 80px;

  @media (max-width: 600px) {
    padding: 12.2rem 32px 7.5rem 32px;
  }

  @media (max-width: 425px) {
    padding: 12.2rem 16px 7.5rem 16px;
  }

  .main-info-platform {
    justify-content: space-between;
    gap: 7.5rem;

    @media (max-width: 920px) {
      flex-direction: column;
    }

    figure {
      max-width: 820px;
      width: 100%;
      margin-right: -30rem;

      @media (max-width: 920px) {
        margin-right: 0rem;
      }

      img {
        width: 100%;
        object-fit: scale-down;
      }
    }
  }

  .main-info-bussiness {
    justify-content: space-around;
    gap: 7.5rem;

    @media (max-width: 920px) {
      flex-direction: column-reverse;
    }

  }

  button,
  a {
    border: none;
    outline: none;
    border-radius: 21px;
    min-height: 44px;
    font-size: 1.6rem;
    color: white;
    line-height: 2rem;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
  }
`;

export const CostumerSection = styled.section`
  padding: 5rem 80px 3.5rem 80px;
  background-color: ${landingPalette.blueThree};
  position: relative;
  z-index: 0;

  ::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: " ";
    background-color: #171717;
    opacity: 0.54;
    z-index: -1;
  }

  @media (max-width: 600px) {
    padding: 5rem 32px 3.5rem 32px;
  }

  @media (max-width: 435px) {
    padding: 5rem 16px 3.5rem 16px;
  }

  h2 {
    font-size: 3.8rem;
    line-height: 4.8rem;
    color: white;
    margin-bottom: 5rem;
    display: flex;
    align-content: center;
    align-self: flex-start;

    img {
      max-width: 68px;
      width: 100%;
      margin-top: -20px;
      object-fit: scale-down;
    }
  }
`;

export const FaqsContainer = styled.div`
  padding: 5rem 80px 0 80px;

  @media (max-width: 600px) {
    padding: 5rem 32px 0 32px;
  }

  @media (max-width: 425px) {
    padding: 5rem 16px 0 16px;
  }

  .faqs {
    padding: 0;
    width: 100%;
    .faqs-list {
      width: 100%;
    }
  }

  .main-landing-container-column {
    width: 100%;
    align-items: flex-start;
    flex-shrink: 0;
  }
`;

export const ContactSection = styled.section`
  padding: 12rem 80px 11rem 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    padding: 17rem 32px 11rem 32px;
  }

  @media (max-width: 425px) {
    padding: 17rem 16px 11rem 16px;
  }

  .title-contact {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 1080px) {
      justify-content: center;
      img {
        position: absolute;
        z-index: 0;
      }

      .figure-one {
        left: 8%;
      }

      .figure-two {
        right: 8%;
      }
    }
  }



  button,
  a {
    border: none;
    outline: none;
    border-radius: 21px;
    height: 44px;
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 3.5rem;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .action-button {
    color: white;
    background-color: ${landingPalette.orange};
  }

  .see-more-button {
    background-color: ${landingPalette.bgBlue};
    color: ${landingPalette.blue};
  }
`;
